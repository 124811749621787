import React from "react";
import TemplateWrapper from "../../layouts";
import { MarginExcluder } from "../../components/styled-components/structures";
import {
  SuperHeading,
  Paragraph,
} from "../../components/styled-components/elements";

const Fees = () => (
  <TemplateWrapper>
    <MarginExcluder>
      <SuperHeading>Term fees</SuperHeading>
      <Paragraph>
        We have 3, 12 week terms annually. The fees are due at the beginning of
        each term. Every new member is given a complementary session so they can
        decide if they would like to become a member of the choir. Each term
        costs £108 per term.
      </Paragraph>
      <Paragraph>
        This can be paid per term or in 3 monthly equal payments.
      </Paragraph>
      <Paragraph>
        Cheques should be made payable to Cheshire Community Choirs.
      </Paragraph>
      <Paragraph>
        If you wish to join Choir+, this is a ‘pay as you go’ choir. Each
        session costs £7 which includes tea and coffee.
      </Paragraph>
    </MarginExcluder>
  </TemplateWrapper>
);

export default Fees;
